import React, {Component} from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { emailExpression } from '../buttons/emailValidateExpression.js';
import Tooltip from './tooltip.js'
import ReCAPTCHA from 'react-google-recaptcha';

class EmailForm extends Component{

    constructor(props){
        super(props);
        this.state={
            isEmailShareModalOpen: false,
            isConfirmationModalOpen:false,
            senderName: '',
            isSenderNameValid:false,
            isRecipientEmailValid:false,
            senderNameValidationText:'',
            recipientEmailValidationText:'',
            personalMessage: '',
            recipientEmail:'',
            recaptchaToken:'',
            subject:'',
            shareEmailResponse:''
        }
    }


    handleChange(event) {
        switch(event.target.id){
            case 'sender-name':
                this.validateInput(event.target.value, event.target.id)
                this.setState({senderName: event.target.value});
                break;
            case 'personal-message':
                this.setState({personalMessage: event.target.value});
                break;
            case 'recipient-email':
                this.validateInput(event.target.value, event.target.id)
                this.setState({recipientEmail: event.target.value});
                break;
            case 'subject':
                this.setState({subject:event.target.value});
                break;
        }
    }

    validateInput(text, id) {
        if(id === 'sender-name'){
            if(text.length > 0){
                this.setState({isSenderNameValid:true,
                    senderName: text,
                    subject:`${text} sent you an article on ${this.props.pageTitle}`,
                    senderNameValidationText:''})
            } else {
                this.setState({
                    senderNameValidationText:'Required field',
                    subject:`[Your name] sent you this article on ${this.props.pageTitle}`})
            }
        } else if (id === 'recipient-email') {
            if(text.length > 0){
                let testResult = emailExpression.test(String(text).toLowerCase());
                if(!testResult){
                    this.setState({isRecipientEmailValid:false,
                        recipientEmailValidationText:`${text} is not a valid email`})
                } else {
                    this.setState({isRecipientEmailValid:true,
                        recipientEmailValidationText:''})
                }
            } else {
                this.setState({
                    recipientEmailValidationText:'Required field'})
            }
        }
    }

    submitForm(event) {
        event.preventDefault();
        this.closeEmailShareModal();

        let fetchBody = {
            from: this.state.senderName,
            to: this.state.recipientEmail,
            message: this.state.personalMessage,
            recaptcha: this.state.recaptchaToken,
            link: window.location.href,
            nid: this.props.nodeID
        };
        const url = `${this.props.baseURL}/mail?access_token=${this.props.accessToken}`;
        this.props.ReactPiwik.push(['trackEvent', 'share', 'click', 'email']);
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(fetchBody),
            headers:{
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
        // This will fire as long as we got a response from the server, even if that response is an error
            .then(response => {
                if(typeof response.status !== 'undefined') {
                    switch(response.status) {
                        case 200:
                            // 200 means "Success" and should indicate that our email has been sent. Yay!
                            this.setState({shareEmailResponse:`Thank you for sharing this article. An email has been sent to ${this.state.recipientEmail}.`,
                            isConfirmationModalOpen:true});
                            break;
                        case 401:
                            // 401 means "Unauthorized", and is probably due to a request from a server that is not authorized on our API.
                            this.setState({shareEmailResponse:`An error has occurred. Please try again later. Error code: 401`,
                            isConfirmationModalOpen:true});
                            break;
                        case 403:
                            // 403 means "Forbidden", probably because the ReCaptcha token couldn't be validated with Google's API
                            this.setState({shareEmailResponse:`An error has occurred. Please try again later. Error code: 403`,
                            isConfirmationModalOpen:true});
                            break;
                        case 405:
                            // 405 means "Method not Allowed", and probably indicates an issue with CORS (due to the pre-flight OPTIONS request),
                            //   or a request that is not GET or POST
                            this.setState({shareEmailResponse:`An error has occurred. Please try again later. Error code: 405`,
                            isConfirmationModalOpen:true});
                            break;
                        case 406:
                            // 406 means "Not Acceptable", probably because the request was missing data (required parameters are to, from, and recaptcha)
                            this.setState({shareEmailResponse:`An error has occurred. Please try again later. Error code: 406`,
                            isConfirmationModalOpen:true});
                            break;
                        case 500:
                            // 500 means "Server error", and is probably an issue with the API in Drupal
                            this.setState({shareEmailResponse:`An error has occurred. Please try again later. Error code: 500`,
                            isConfirmationModalOpen:true});
                            break;
                    }
                }
            })
            // This will fire if there was an error that prevented us from making the fetch call or receiving a response from the server
            .catch(error => {
              console.error('Error:', error);
              this.setState({shareEmailResponse:`An error has occurred. Please try again later.`,
              isConfirmationModalOpen:true});
            });
    }

    closeEmailShareModal(){
      this.setState({
          isEmailShareModalOpen: false,
          recaptchaToken:'',
        });
    }

    openEmailShareModal(){
      this.setState({ isEmailShareModalOpen: true });
    }

    closeConfirmationModal(){
      this.setState({
      senderName: '',
      senderNameValidationText:'',
      recipientEmailValidationText:'',
      subject:`[Your name] sent you this article on ${this.props.pageTitle}`,
      personalMessage: `I thought you'd be interested in this article on ${this.props.pageTitle} from ${this.props.customerName}'s Business Energy Advisor.`,
      recipientEmail:'',
      isConfirmationModalOpen:false})
    }

    //ReCAPTCHA functions
    onChange(response) {
      let token = response;
      this.setState({recaptchaToken:token,
                    recaptchaToken: response});
    }

    onExpired(){
      this.setState({recaptchaToken: ''});
    }

    componentDidMount() {
        let presetPersonalMessage = `I thought you'd be interested in this article on ${this.props.pageTitle} from ${this.props.customerName}'s Business Energy Advisor.`;
        let presetSubject = `[Your name] sent you this article on ${this.props.pageTitle}`;
        this.setState({personalMessage:presetPersonalMessage,
            subject:presetSubject})
    }

    render(){
        return(
          <div className='inline-btn'>
            <div onClick={()=> {this.openEmailShareModal()}}>
                <Tooltip message={'Email this page'} position={'top'}>
                  <button className='sml-round-btn btn'>
                    <FontAwesomeIcon
                    icon={faEnvelope}/>
                  </button>
                </Tooltip>
                <p className='inline-right-txt'>Email</p>
              </div>
                <ReactModal
                    isOpen={this.state.isEmailShareModalOpen}
                    onRequestClose={()=>{this.closeEmailShareModal()}}
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                    shouldReturnFocusAfterClose={true}
                    className='modal-content-share'
                    overlayClassName='modal-overlay'>
                    <h2>Send this page to a friend</h2>
                    <FontAwesomeIcon
                    icon={faTimes}
                    onClick={()=>{this.closeEmailShareModal()}}
                    style={{
                        height: '30px',
                        width: '30px',
                        position: 'absolute',
                        top: '25px',
                        right: '20px',
                        cursor: 'pointer'
                    }}/>
                    <form onSubmit={(event) => this.submitForm(event)}>
                        <label>
                            <p>Your name:</p>
                            <input type='text'
                                   id='sender-name'
                                   value={this.state.senderName}
                                   onChange={(event) => this.handleChange(event)}
                                   autoComplete='off'/>
                            <div><p className='errorMessage'>{this.state.senderNameValidationText}</p></div>
                        </label>
                        <label>
                            <p>Send to:</p>
                            <input
                                type='email' id='recipient-email'
                                value={this.state.recipientEmail}
                                onChange={(event) => this.handleChange(event)}
                                autoComplete='off'/>
                            <p>
                                <span>Please note: We do not capture any email addresses.</span>
                            </p>
                            <p className='errorMessage'>{this.state.recipientEmailValidationText}</p>
                        </label>
                        <label>
                            <p>Subject: <span>Optional</span></p>
                            <input
                                type='text'
                                id='subject'
                                value={this.state.subject}
                                onChange={(event) => this.handleChange(event)}
                                autoComplete='off'/>
                        </label>
                        <label>
                            <p>Your personal message: <span>Optional</span></p>
                            <textarea
                                type='text'
                                id='personal-message'
                                value={this.state.personalMessage}
                                onChange={(event) => this.handleChange(event)}
                                autoComplete='off'/>
                        </label>
                        <label>
                            <p>You are emailing this link:</p>
                            <p
                            className='address-preview'>{window.location.host+window.location.pathname}</p>
                        </label>
                        <ReCAPTCHA
                            style={{'margin': '2.5em 0'}}
                            sitekey="6LfTKpoUAAAAAEoAvmUcb43CebRfKeN9YvCdhkLM"
                            onChange={this.onChange.bind(this)}
                            onExpired={this.onExpired.bind(this)}
                        />
                        <button type='submit' value='Submit' className='btn' disabled={
                            this.state.senderName === '' || this.state.recipientEmail === '' || this.state.recaptchaToken === ''}>
                          Send message
                        </button>
                    </form>
                </ReactModal>
                <ReactModal
                    isOpen={this.state.isConfirmationModalOpen}
                    onRequestClose={()=>{this.closeConfirmationModal()}}
                    shouldCloseOnOverlayClick={true}
                    shouldCloseOnEsc={true}
                    shouldReturnFocusAfterClose={true}
                    className='modal-content-share modal-confirmation'
                    overlayClassName='modal-overlay'>
                    <p>{this.state.shareEmailResponse}</p>
                    <button
                    onClick={()=>{this.closeConfirmationModal()}}
                    className='btn'>
                      Close
                    </button>
                </ReactModal>
              </div>
        )
    }

}

export default EmailForm;

import React, {Component} from 'react';
import Loader from 'react-loaders';
import Header from '../elements/headers/header.js';
import Breadcrumbs from '../elements/navs/breadcrumbs.js';
import VerticalCard from '../elements/cards/verticalCard.js';
import NoMatch from './noMatch.js';

class Category extends Component {

  constructor(props){
    super(props);
    this.state = {
      categoryData:[],
      breadcrumbLocations:[],
      categoryParent: "",
      isError:false
    }
  }
  buildVerticalCards(articles){
      return articles.map(article => {
        return(
          <VerticalCard
            data-test='vertical-card'
            type='category_article'
            title={article.title}
            path={article.path}
            key={article.id}
            leadImage={article.lead_image}
            teaser={article.teaser}
          />
        )
     })
  }

  async componentDidMount(){
    window.scrollTo(0,0);
    this.props.ReactPiwik.push(['setCustomUrl', window.location.href]);
    this.props.ReactPiwik.push(['trackPageView']);
    const { id } = this.props.match.params;

    var response;
    var categoryDataJson;

    try {
      response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.allArticles}/${id}?exclude_articles=${this.props.excludedArticles}&access_token=${this.props.accessToken}`);
      if(response.status !== 200) {
        console.log('blockResponse fetch error', response);
        this.setState({error:response.status, statusText: response.statusText, isError: true})
      }
    } catch(err) {
      console.log(err);
      this.setState({ isError:true })
    }

    if(this.state.isError !== true) {
      try {
        categoryDataJson = await response.json();
        //Building breadcrumb link(s) that will be stored in state and passed down as props to Breadcrumb component
        let hierarchyData = categoryDataJson[0]['categories'][0];
        let breadcrumbLinks = this.props.buildBreadcrumbLinks('categoryPage', hierarchyData);
        //Capturing parent of the category (Business types vs Technology) to render appropriate block
        let categoryParent = categoryDataJson[0]['categories'][0]['parent'];

        this.setState({categoryData:categoryDataJson,
          breadcrumbLocations:breadcrumbLinks,
          categoryParent:categoryParent});
          document.title = `Business Energy Advisor | ${categoryDataJson[0]['title']}`;
        }
        catch(err) {
          console.log(err);
          this.setState({ isError:true, error:400, statusText: 'Could not generate categories' })
        }
      }
  }

  render() {
    if(this.state.isError === true){
      return(
        <div data-test='detail-fetch detail' className='detail'>
          <NoMatch error={this.state.error} statusText={this.state.statusText}/>
        </div>
      )
    } else if(this.state.categoryData.length === 0 || this.state.breadcrumbLocations.length === 0 || Object.keys(this.props.URLPaths).length === 0){
        return(
          <div data-test='category' className='category'>
            <Loader type='line-scale'
            style={{transform: 'scale(2)'}}/>
          </div>
        )
      } else {
      return(
        <div data-test='category' className='category'>
          <Header
            data-test='header'
            type={'category'}
            style={this.state.categoryData[0]['categories'][0]['lead_image']['src']}
            title={this.state.categoryData[0]['categories'][0]['name']}
            />
          <Breadcrumbs
            data-test='breadcrumbs'
            locations={this.state.breadcrumbLocations}
          />
          <div className='row-center'>
            <div className='col-4-container'>
              {this.buildVerticalCards(this.state.categoryData)}
            </div>
          </div>
          <div className='row bg-gray text-center'>
            {this.props.renderCTAButton(this.state.categoryParent, 'Category')}
          </div>
        </div>
      )
    }
  }
}


Category.propTypes = {

};

export default Category;

import React from 'react';
import EmailForm from '../blocks/emailForm.js'
import Tooltip from '../blocks/tooltip.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPrint, faShare, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons' ;

const TextExtendButton = (props) => {
  
  let encodedURI = encodeURIComponent(window.location.href);
  let twitterBody = `Learned a bunch about ${props.pageTitle} from ${props.customerName}'s Business Energy Advisor: `;
  let linkedInBody = `Interesting article on ${props.pageTitle} from ${props.customerName}'s Business Energy Advisor`;

  return(
      <div className='clear text-extend-button no-print'>
        <div className='float-right'>
          {/*Email*/}
            <EmailForm
            customerName={props.customerName}
            pageTitle={props.pageTitle}
            ReactPiwik={props.ReactPiwik}
            baseURL={props.baseURL}
            nodeID={props.nodeID}
            accessToken={props.accessToken}
            />
          {/*Print*/}
          <div className='inline-btn'
            onClick={() => {window.print(); props.ReactPiwik.push(['trackEvent', 'share', 'click', 'print'])}}>
            <Tooltip message={'PDF or print this page'} position={'top'}>
              <button className='sml-round-btn btn'>
                <FontAwesomeIcon
                icon={faPrint}/>
              </button>
            </Tooltip>
            <p className='inline-right-txt'>Print/PDF</p>
          </div>
          {/*Share*/}
          <div
            className='round-dropdown-container inline-btn'
            onClick={() => props.toggleSocialMediaOptions(true)}
            onBlur={() => props.toggleSocialMediaOptions()}
            tabIndex="0">
              <div className='round-dropdown-click'>
                <Tooltip message={'Share this page on social media'} position={'top'}>
                  <button className='sml-round-btn btn'>
                    <FontAwesomeIcon
                    icon={faShare}/></button>
                </Tooltip>
                <p className='inline-right-txt'>Share
                  <FontAwesomeIcon
                  icon={faChevronDown}/>
                </p>
              </div>
            {/*Share - Dropdown options*/}
              <div
              className={props.showSocialMediaOptions ? 'round-dropdown-content' : 'display-none'}>
                {/*Facebook*/}
                <div className='btn-dropdown'
                onMouseDown={()=>{ props.popupCenter(`https://www.facebook.com/sharer.php?u=${encodedURI}`,700,500); props.ReactPiwik.push(['trackEvent', 'share', 'click', 'Facebook'])}}>
                  <button className='sml-round-btn btn'>
                    <FontAwesomeIcon
                    icon={faFacebookF}/>
                  </button>
                  <p className='inline-right-txt'>Facebook</p>
                </div>
                {/*Twitter*/}
                <div className='btn-dropdown'
                onMouseDown={()=>{ props.popupCenter(`https://twitter.com/intent/tweet?url=${encodedURI}&text=${twitterBody}`,700,500); props.ReactPiwik.push(['trackEvent', 'share', 'click', 'Twitter'])}}>
                  <button className='sml-round-btn btn'>
                    <FontAwesomeIcon
                    icon={faTwitter}/>
                  </button>
                  <p className='inline-right-txt'>Twitter</p>
                </div>
                {/*LinkedIn*/}
                <div className='btn-dropdown'
                onMouseDown={()=>{props.popupCenter(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedURI}&title=BEA&summary=${linkedInBody}`,700, 500); props.ReactPiwik.push(['trackEvent', 'share', 'click', 'LinkedIn'])}}>
                  <button className='sml-round-btn btn'>
                    <FontAwesomeIcon
                    icon={faLinkedinIn}/>
                  </button>
                  <p className='inline-right-txt'>LinkedIn</p>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
}

TextExtendButton.propTypes = {

};

export default TextExtendButton;

import React from 'react';

class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayTooltip: false
    }
    this.hideTooltip = this.hideTooltip.bind(this)
    this.showTooltip = this.showTooltip.bind(this)
  }

  hideTooltip () {
    this.setState({displayTooltip: false})

  }
  showTooltip () {
    this.setState({displayTooltip: true})
  }

  render() {
    return (
      <span className='tooltip'
          onMouseLeave={this.hideTooltip}
        >
        {this.state.displayTooltip &&
        <div className={`tooltip-bubble tooltip-${this.props.position}`}>
          <div className='tooltip-message'>{this.props.message}</div>
        </div>
        }
        <span
          className='tooltip-trigger'
          onMouseOver={this.showTooltip}
          >
          {this.props.children}
        </span>
      </span>
    )
  }
}


Tooltip.propTypes = {

};

export default Tooltip;

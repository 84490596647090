import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class Filter extends Component{

  constructor(props){
    super(props);
    this.state={
      showFilterDropdownOptions:false,
    }
  }

buildFilters(labels, view){
    if(view === 'dropdown'){
      return labels.map( label => {
        return(
          <li>
            <button
            className={this.props.currentCategorySelection === label.name ? 'selected link' : 'not-selected link'}
            onClick={() =>
              {this.props.updateFilter(label.name)
              this.toggleFilterDropdownOptions()}
            }>
              {label.name}
            </button>
          </li>
        )
      })
    } else {
      return labels.map( label => {
        return(
          <li>
          <button
          className={this.props.currentCategorySelection === label.name ? 'current btn' : 'not-current btn'}
          onClick={() => this.props.updateFilter(label.name)}>
          {label.name}
          </button>
          </li>
        )
      })
    }
  }

  toggleFilterDropdownOptions(){
    this.setState(previousState => {
      return{
        showFilterDropdownOptions: !previousState.showFilterDropdownOptions
      }
    })
  }

  render(){
    return (
      <nav className='filter-nav'>
      <div className='desktop-only'>
        <h3>Filter by category</h3>
          <ul>
            <li>
              <button
              className={this.props.currentCategorySelection === 'All' ? 'current btn' : 'not-current btn'}
              onClick={() => this.props.updateFilter('All')}>
                All
              </button>
            </li>
            {this.buildFilters(this.props.labels, 'buttons')}
          </ul>
        </div>
        {/*Mobile only*/}
        <div className='mobile-only'>
          <h3>Filter by category</h3>
          <button
          className='btn'
          onClick={() => {this.toggleFilterDropdownOptions()}}>
            {this.props.currentCategorySelection}
              <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <div
          className={this.state.showFilterDropdownOptions === true ? 'filter-contents' : 'display-none filter-contents'}>
            <ul>
              <li>
                <button
                className={this.props.currentCategorySelection === 'All' ? 'selected link' : 'not-selected link'} href='#'
                onClick={() =>
                  {this.props.updateFilter('All')
                  this.toggleFilterDropdownOptions()}
                }>
                  All
                </button>
              </li>
              {this.buildFilters(this.props.labels, 'dropdown')}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Filter.propTypes = {

};

export default Filter;

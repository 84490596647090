import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import SearchResults from '../elements/blocks/searchResults.js';
import SearchBar from '../elements/navs/searchBar.js';
import Loader from 'react-loaders';
import NoMatch from './noMatch.js'


class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue:'',
      searchResults: [],
      page: 0,
      isLoading: true,
      totalResults: 0,
      value: '',
      keywords: '',
      from: ''
    };
  }

  onInitialSearch(event){
    if (event.target.value === '' && event.key === 'Enter') {
      return;
    }
    if (event.key === "Enter" || event.keyCode === 8) {
      this.setState({
        searchValue:event.target.value
      })
      this.fetchNewStories(event.target.value,0);
      this.props.history.push(`/search/${event.target.value}`);
    }
  }
  async fetchNewStories(value, page){
    const { id } = this.props.match.params;
    this.setState({isLoading: true});
    const response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.search}?keywords=${id}&limit=10&offset=${this.state.page + 10}&access_token=${this.props.accessToken}`);
    const searchJSON = await response.json();

    page === 0 ?
    //is this request is for the 'first' page?
      this.setState({
        searchResults: searchJSON.nodes,
        page: searchJSON.page,
        totalResults: searchJSON.totalResults,
        isLoading: false,
      })

    :
    //if the request is for past the 'first' page
      this.setState((previousState) => {
        return{
          searchResults: previousState.searchResults.concat(searchJSON.nodes),
          totalResults: searchJSON.totalResults,
          isLoading: false,
          page:previousState.page + 10
        }
      })
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    let searchValue = id;
    var response;
    try {
      response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.search}?keywords=${id}&limit=10&offset=0&access_token=${this.props.accessToken}`);
      if(response.status !== 200) {
        console.log('CustomizationsResponse feth error', response)
        this.setState({error:response.status, statusText: response.statusText, isError: true})
      }
    } catch(err) {
      console.log('Feth Error', err);
      this.setState({ isError:true, error:400, statusText: 'Problem trying to search' })
    }

    if(this.state.isError !== true) {
      var searchJSON = await response.json();
      this.setState({
        searchValue: searchValue,
        searchResults: searchJSON.nodes,
        page: searchJSON.page,
        totalResults: searchJSON.totalResults,
        isLoading: false,
      })
      this.props.ReactPiwik.push(['trackSiteSearch', id, false, searchJSON.totalResults])
      this.setState({
        isLoading:false,
      })
    }
  }

  render() {
    if(this.state.isError === true){
      return(
        <div data-test='detail-fetch detail' className='detail'>
          <NoMatch error={this.state.error} statusText={this.state.statusText}></NoMatch>
        </div>
      )
    }
    if(this.state.isLoading === true){
      return(
      <div className='search'>
        <div className='main-body'>
          <div className='search-container'>
            <SearchBar
              onInitialSearch={this.onInitialSearch.bind(this)}
              toSearch={this.props.toSearch}
              searchValue={this.state.searchValue}
              fetchNewStories={this.fetchNewStories.bind(this)}
              page={this.state.page}
              type={'search'}
            />
            <Loader type='line-scale'
            style={{transform: 'scale(2)'}}
            />
          </div>
        </div>
      </div>
      )
    } else if (this.state.totalResults === undefined) {
      return(
        <div className='search'>
          <div className='main-body'>
            <div className='search-container'>
              <SearchBar
                toSearch={this.props.toSearch}
                onInitialSearch={this.onInitialSearch.bind(this)}
                page={this.state.page}
                type={'search'}
              />
            </div>
            <div className='total-results'>{`0 results for "${this.state.searchValue}"`}</div>
              <p>Your search returned no results.</p>
          </div>
      </div>
      )
    } else {
      return(
        <div className='search'>
          <div className='main-body'>
           <div className='search-container'>
           <SearchBar
             onInitialSearch={this.onInitialSearch.bind(this)}
             fetchNewStories={this.fetchNewStories.bind(this)}
             toSearch={this.props.toSearch}
             searchValue={this.state.searchValue}
             page={this.state.page}
             value={this.state.searchValue}
             type={'search'}
           />
            </div>
            <div className='total-results'>{`${this.state.searchResults.length} of ${this.state.totalResults} results for "${this.state.searchValue}"`}</div>
              <SearchResults
                list={this.state.searchResults}
                page={this.state.page}
                searchValue={this.state.searchValue}
                fetchNewStories={this.fetchNewStories.bind(this)}
                totalResults={this.state.totalResults}
                searchResultsLength={this.state.searchResults.length}
                id={this.props.id}
              />
            </div>
        </div>
      )
    }
  }
};


Search.propTypes = {

};

export default withRouter(Search);

import React from 'react';
import { Link } from 'react-router-dom';

const SmallHorizontalCard = (props) => {

  return(
    <Link to={props.path} className='card small-horizontal-card col-3'
      data-test='smallHorizontalCard'>
      <div className='col-full'>
        <img src={props.large} srcSet={`${props.small} 300w, ${props.medium} 768w, ${props.large} 1280w`} alt={props.alt}/>
      </div>
      <div className='col-full'>
        <h3>{props.title}</h3>
      </div>
    </Link>
  )
};

SmallHorizontalCard.propTypes = {

}

export default SmallHorizontalCard;

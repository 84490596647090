import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import MobileScreen from '../blocks/mobileScreen.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class MobileNavigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      screenLevel: 'home',
      screenOpen: false,
      screenParent: false
    };
  }
  updateScreenLevel(level){
    this.setState({
      screenLevel:level
    })
  }
  toggleScreen(isScreenOpen, level){
    this.setState({
      screenOpen:isScreenOpen,
      screenLevel: level
    })
  }
  scrollToContact(isScreenOpen, level){
    this.setState({
      screenOpen:isScreenOpen,
      screenLevel: level
    })
    this.props.scrollToFooter();
  }
  buildMobileNavCategories(categories){
      return categories.map(category => {
        return <Link to={category.path} onClick={()=>{
          this.toggleScreen(false, 'home')
        }} key={category.id}>{category.name}</Link>
      })
  }
  render() {
    if(this.props.URLPaths.length === 0) {
        return(
          <div data-test='category'>loading...</div>
        )
      } else {
        return (
          <div className='mobile-navigation-container'>
            <div className='mobile-navigation main-nav'>
              <Link className='toggle-btn no-print' to='#' onClick={()=>this.toggleScreen(true, 'home')}>
                <FontAwesomeIcon icon={faBars} />
              </Link>
              <div className={this.state.screenOpen ? 'mobile-screen-container open' : 'mobile-screen-container hide'}>
                <MobileScreen
                  screenLevel={this.state.screenLevel}
                  screenOpen={this.state.screenOpen}
                  URLPathsBiz={this.props.URLPaths.businessTypes}
                  URLPathsTech={this.props.URLPaths.technologies}
                  categoriesBiz={this.props.businessCategories}
                  categoriesTech={this.props.technologyCategories}
                  URLPaths={this.props.URLPaths.technologies}
                  updateScreenLevel={this.updateScreenLevel.bind(this)}
                  toggleScreen={this.toggleScreen.bind(this)}
                  buildMobileNavCategories={this.buildMobileNavCategories.bind(this)}
                  buildCustomizations={this.props.buildCustomizations}
                  scrollToContact={this.scrollToContact.bind(this)}
                />
            </div>
            </div>
            <div id='overlay' className={this.state.screenOpen ? 'open' : 'hide'} onClick={()=>this.toggleScreen(false, 'home')}>
            </div>
          </div>
        );
     }
  }
}

MobileNavigation.propTypes = {

};

export default MobileNavigation;

import React from 'react';

const TableOfContents = (props) => {

  //logic to loop through the NodeList of headers and subheaders, then create table of contents, apply classes for styling.
  let buildTableOfContents = (tocNodeList) => {
    let result = [];
    for (var i = 0; i < tocNodeList.length; i++) {
      let tocLabel;
      if(tocNodeList[i].localName === 'h2'){
        tocLabel = tocNodeList[i]
        result.push(
          <a className={props.currentContent === tocLabel.id ? 'main-header current': 'main-header'}
          onClick={function() { props.ReactPiwik.push(['trackEvent', 'TOC', 'click', tocLabel.innerHTML])}}
          href={`#${tocNodeList[i].id}`}
          key={i}
          toc-data={tocNodeList[i].id}>
          {tocLabel.innerText}
          </a>
        )
      } else if (tocNodeList[i].localName === 'h3') {
        tocLabel = tocNodeList[i]
        result.push(
          <a className={props.currentContent === tocLabel.id ? 'sub-header current': 'sub-header'}
          onClick={function() { props.ReactPiwik.push(['trackEvent', 'TOC', 'click', tocLabel.innerHTML])}}
          key={i}
          href={`#${tocNodeList[i].id}`}
          toc-data={tocLabel.id}>
          {tocLabel.innerText}
          </a>
        )
      } else {
        tocLabel = tocNodeList[i]
        result.push(
          <a className={props.currentContent === tocLabel.id ? 'main-header current': 'main-header'}
          onClick={function() { props.ReactPiwik.push(['trackEvent', 'TOC', 'click', tocLabel.firstChild.innerText])}}
          key={i}
          href={`#${tocNodeList[i].id}`}
          toc-data={tocLabel.id}>
          {tocLabel.firstChild.innerText}
          </a>
        )
      }
    }
    return result;
  }

  return(
    <nav
    className={props.displayTOC ? 'side-nav no-print' : 'side-nav no-print display-none'}>
      {buildTableOfContents(props.tocNodeList)}
    </nav>
  )
}

TableOfContents.propTypes = {

};

export default TableOfContents;

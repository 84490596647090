import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

const VerticalCard = (props) => {
  if (props.type === 'category_article') {
    return(
      <Link to={props.path} className='card vertical-card col-4 category-article' data-test='verticalCard' key={props.id}>
      <img src={props.leadImage.large} srcSet={`${props.leadImage.thumbnail} 300w, ${props.leadImage.medium} 768w, ${props.leadImage.large} 1280w`} alt={props.leadImage.alt}/>
        {props.title === '' ? null : <h3>{props.title}</h3>}
      </Link>
    )
  } else if(props.type === 'customer_resource'){
      return(
        <a href={props.path} className='vertical-card col-4 resources card' data-test='verticalCard' key={props.id} rel="noopener noreferrer" onClick={function() { props.ReactPiwik.push(['trackEvent', 'resource', 'click', props.title]);}}>
          <div className='card-content'>
            {props.leadImage === '' ? null : <img src={props.leadImage.src} alt={props.leadImage.alt}/> }
            {props.title === '' ? null : <h3>{props.title}</h3>}
            {props.body === '' ? null : <p>{props.body}</p>}
          </div>
        </a>
      )
    } else {
      return(
        <Link to={props.cardData.path} className='card vertical-card col-4 related-content' data-test='verticalCard' key={props.id}>
        <img src={props.cardData.lead_image.large} srcSet={`${props.cardData.lead_image.thumbnail} 300w, ${props.cardData.lead_image.medium} 768w, ${props.cardData.lead_image.large} 1280w`} alt={props.cardData.lead_image.alt}/>
        <h3>{props.title}</h3>
        </Link>
      )
    }
};

VerticalCard.propTypes = {

};

export default VerticalCard;

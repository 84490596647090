import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

const BackToTopButton = (props) => {
  return(
    <button className='large-round-btn back-to-top btn' style={{display: props.scrolling ? 'none' : 'block'}}
    onClick={()=>{window.scrollTo(0, 0); props.ReactPiwik.push(['trackEvent', 'Back to top', 'click']);}}>
      <FontAwesomeIcon icon={faChevronUp} />
    </button>
  )
}

BackToTopButton.propTypes = {

};

export default BackToTopButton;

import React, {Component} from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


class LinksBlock extends Component{

  constructor(props){
    super(props);
    this.state={
      caseStudyModalOpen: false,
      caseStudyData:[]
    }
  }

  buildLinks = (links) => {
    return links.map(link => {
      if(link.ownership === 'theirs'){
        return(
            <a
            href={link.path}
            target='_blank'
            rel="noopener noreferrer"
            style={{fontWeight:'normal'}}>
              <p>{link.title}</p>
            </a>
        )
      } else {
        return(
          <button className='link' onClick={() => this.openCaseStudy(link.path)}>
          <p>{link.title}</p>
          </button>
        )
      }
    })
  }

  onCloseModal = () => {
    this.setState({ caseStudyModalOpen: false });
  }

  async openCaseStudy(caseStudyPath){
    const response = await fetch(`${this.props.baseUrl}${caseStudyPath}?access_token=${this.props.accessToken}`);
    const caseStudyJson = await response.json();
    this.setState({caseStudyData:caseStudyJson})
    this.setState({ caseStudyModalOpen: true });
  }

  render(){
    if(this.props.caseStudies.length === 0){
      return(<div></div>);
    }
      return(
        <div>
          <div
          data-test='links-block'
          id={this.props.scrollToID}
          className={`row bg-gray text-center links-block ${this.props.tableOfContentsClass}`}>
          <h3>{this.props.caseStudies.length === 1 ? 'Case study' : 'Case studies'}</h3>
              {this.buildLinks(this.props.caseStudies)}
          </div>
          <ReactModal
            isOpen={this.state.caseStudyModalOpen}
            onRequestClose={this.onCloseModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            shouldReturnFocusAfterClose={true}
            className='modal-content'
            overlayClassName='modal-overlay'
            >
            <h2>{this.state.caseStudyData.title}</h2>
            <FontAwesomeIcon
            icon={faTimes}
            onClick={()=>{this.onCloseModal()}}
            style={{
              height: '25px',
              width: '25px',
              position: 'absolute',
              top: '40px',
              right: '35px',
              cursor: 'pointer'
            }}/>
            <div
              dangerouslySetInnerHTML={{__html: this.state.caseStudyData.body}}/>
            <button
            className='btn'
            onClick={()=>{this.onCloseModal()}}>
            Close</button>
          </ReactModal>
        </div>
      )
  }
}


LinksBlock.propTypes = {

};

export default LinksBlock;

import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Navigation = (props) => {
    return (
        <div data-test='navigation' className='nav-container container'>
          <nav className='main-nav clear'>
            <div className='logo float-left'>
              <Link to='/'><img src={props.buildCustomizations['logo']['path']} alt={props.buildCustomizations['logo']['alt']}/></Link >
            </div>
            <ul className='float-left no-print'>
              <li>
                <Link to='/' className='dropdown-btn'>Home</Link>
              </li>
              <li className='dropdown'>
                <Link className='dropdown-btn' to={props.URLPaths.businessTypes}>Business types<FontAwesomeIcon icon={faChevronDown} /></Link>
                <div className='dropdown-content'>
                  {props.buildTopNavCategories(props.businessCategories)}
                  <Link className='nav-view-all' to={props.URLPaths.businessTypes}>View all business sectors</Link>
                </div>
              </li>
              <li className='dropdown'>
                <Link className='dropdown-btn' to={props.URLPaths.technologies}>Technologies<FontAwesomeIcon icon={faChevronDown} /></Link>
                <div className='dropdown-content'>
                  {props.buildTopNavCategories(props.technologyCategories)}
                  <Link className='nav-view-all' to={props.URLPaths.technologies}>View all technologies</Link>
                </div>
              </li>
              <li>
                <a className='dropdown-btn' href='#contact-us'>Contact us</a>
              </li>
            </ul>
            <ul className='float-right no-print'>
              <li>
                <a href={props.buildCustomizations.url}>{props.buildCustomizations['back_to_site_text']}</a>
              </li>
            </ul>
          </nav>
        </div>
    );
  }


Navigation.propTypes = {

};

export default Navigation;

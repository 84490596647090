import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = (props) => {

  let buildBreadcrumbs = (locations) => {
    return locations.map((location, index) => {
      //if this is the last link in the breadcrumb group, do not make the link clickable
      if(index === locations.length - 1) {

        return(
          <li key={location.text}>
            <Link
            className='active'
            to={location.href}
            key={location.text}>{location.text}</Link>
          </li>
        )
      } else {
        return(
          <li key={location.text}>
            <Link
            to={location.href}
            key={location.text}>{location.text}</Link>
          </li>
        )
      }
    })
  }

  return (
    <div className='breadcrumbs-container container'>
      <nav>
        <ul className='breadcrumbs'>
          <li><Link to='/' key='home'>Home</Link></li>
          {buildBreadcrumbs(props.locations)}
        </ul>
      </nav>
    </div>
  );
}

Breadcrumbs.propTypes = {

};

export default Breadcrumbs;

import React, {Component} from 'react';
import Loader from 'react-loaders';
import Header from '../elements/headers/header.js';
import Breadcrumbs from '../elements/navs/breadcrumbs.js';
import Filter from '../elements/navs/filter.js';
import SmallHorizontalCard from '../elements/cards/smallHorizontalCard.js';
import NoMatch from './noMatch.js';

class CategoryAll extends Component {
  constructor(props){
    super(props);
    this.state = {
      categoryAllData:[],
      breadcrumbLocations:[],
      currentCardData: [],
      originalCardData:[],
      currentCategorySelection:'All',
      styles: [],
    }
  }

  initialCardFilter(type, articles){
    return articles.filter(article => {
      return article.categories.some(category =>
        category.parent === type);
      }).map( article => {
        return article;
      })
    }

    updateFilter(filterTerm){
      let original = this.state.originalCardData;
      //If user either selects 'All' or clicks on current selected filter to un-select it put back originalCardData as state of CurrentCardData
      if(filterTerm === 'All' || filterTerm === this.state.currentCategorySelection){
        this.setState({currentCategorySelection:'All',
                      currentCardData:original})
      //otherwise update the filterTerm state, filter cards with user selected category. Update state of currentCardData to the result.
      } else {
        let result = [];
        for(let i = 0; i < original.length; i++){
          for(let j = 0; j < original[i]['categories'].length; j++){
            if(original[i]['categories'][j]['name'] === filterTerm){
              result.push(original[i])
            }
          }
        }
        this.setState({currentCategorySelection:filterTerm,
                      currentCardData:result})
      }
      this.props.ReactPiwik.push(['trackEvent', 'filter', 'click', filterTerm])
    }

  //envoked during initial render or when state of currentCardData updates
  buildCards(articles){
    return articles.map(article => {
      return (
        <div key={article.id}>
          <SmallHorizontalCard
            data-test='small-horizontal-card'
            title={article.title}
            path={article.path}
            key={this.state.currentCategorySelection}
            style={article.lead_image.src}
            small={article.lead_image.thumbnail}
            medium={article.lead_image.medium}
            large={article.lead_image.src}
            alt={article.lead_image.alt}
          />
        </div>
      )
    })
  }

  async componentDidMount(){
    window.scrollTo(0, 0);
    this.props.ReactPiwik.push(['setCustomUrl', window.location.href]);
    this.props.ReactPiwik.push(['trackPageView']);

    var response;

    try{
      response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.allArticles}?exclude_categories=${this.props.excludedCategories}&exclude_articles=${this.props.excludedArticles}&access_token=${this.props.accessToken}`);
      if(response.status !== 200) {
        console.log('Feth Error', response)
        this.setState({error:response.status, statusText: response.statusText, isError: true})
      }
    } catch(err) {
      console.log(err);
      this.setState({ isError:true, error:400, statusText: 'Could not fetch all categories' })
    }

    if(this.state.isError !== true) {
      const categoryAllJson = await response.json();
      //Building breadcrumb link(s) that will be stored in state and passed down as props to Breadcrumb component
      let hierarchyData = this.props.type;
      let breadcrumbLinks = this.props.buildBreadcrumbLinks('categoryAllPage', hierarchyData);
      //For filtering through cards
      //Set up initial array card data based on Business Types or Technology
      let categoryFilteredData = this.initialCardFilter(this.props.type, categoryAllJson);
      //Preserve the initial array of card data as the original to refer back to when user manipulates thier cards with filters
      this.setState(
        {
          categoryAllData:categoryAllJson,
          breadcrumbLocations:breadcrumbLinks,
          originalCardData:categoryFilteredData,
          currentCardData:categoryFilteredData
        });
        document.title = `Business Energy Advisor | ${this.props.type}`;
    }
  }

  render() {
    if(this.state.isError === true){
      return(
        <div data-test='detail-fetch detail' className='detail'>
          <NoMatch error={this.state.error} statusText={this.state.statusText}></NoMatch>
        </div>
      )
    }
    if(this.state.breadcrumbLocations.length === 0 || this.state.currentCardData.length === 0 || Object.keys(this.props.URLPaths).length === 0 ){
      return(
        <div data-test='category-all' className='category-all'>
          <Loader type='line-scale'
          style={{transform: 'scale(2)'}}/>
        </div>
      )
    } else {
      return (
        <div data-test='category-all' className='category-all'>
          <Header
            data-test='header'
            type={'categoryAll'}
            title={this.props.type}
            style={this.props.categoriesData['lead_image']['src']}
            medium={this.props.categoriesData['lead_image']['medium']}
            onSetResult={this.props.onSetResult}
          />
          <Breadcrumbs
            data-test='breadcrumbs'
            locations={this.state.breadcrumbLocations}
          />
          <div className='row-center filter-cards'>
            <Filter
            data-test='filter'
            labels={this.props.categories}
            currentCategorySelection={this.state.currentCategorySelection}
            updateFilter={this.updateFilter.bind(this)}
            ReactPiwik={this.props.ReactPiwik}
            />
            <div className='col-3-container card-container'>
              {this.buildCards(this.state.currentCardData)}
            </div>
          </div>
          <div className='row bg-gray text-center cta-block'>
            {this.props.renderCTAButton(this.props.type, 'CategoryAll')}
          </div>
        </div>
      )
    }
  }
}

CategoryAll.propTypes = {

};

export default CategoryAll;

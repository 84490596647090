import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class Dropdown extends Component {

  constructor(props){
    super(props);
    this.state = {
      showChartDropdownOptions:false,
      currentSelectedChart: '',
    }
  }
  //shows or hides our dropdown menu
  toggleChartOptions(isButtonClick){
    if(isButtonClick === true){
      this.setState(previousState => ({
        showChartDropdownOptions: !previousState.showChartDropdownOptions
      }));
    } else {
      this.setState({showChartDropdownOptions:false})
    }
  }

  updateDropdownTitle(title){
    this.setState({currentSelectedChart: title})
  }
  //loop through the chart data to create the text for our dropdown option. We also add an onMouseDown event that passes the src, alt, and title data attributes.
  buildDropdownOptions(items){
    //polyfill if Object.keys does not exist for browser
    if (!Object.keys) {
    Object.keys = (function() {
      'use strict';
      var hasOwnProperty = Object.prototype.hasOwnProperty,
          hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
          dontEnums = [
            'toString',
            'toLocaleString',
            'valueOf',
            'hasOwnProperty',
            'isPrototypeOf',
            'propertyIsEnumerable',
            'constructor'
          ],
          dontEnumsLength = dontEnums.length;
      return function(obj) {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }
        var result = [], prop, i;
        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }
        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    }());
  }
  let JSXResults = [];
  Object.keys(items).forEach(key => {
    JSXResults.push(
      <div
      onMouseDown={()=> {
        this.props.updateCharts(items[key]['charts'])
        this.updateDropdownTitle(items[key]['title'])
      }}
      >
        <button className='link'>
          {items[key]['title']}
        </button>
      </div>
    )
  })

  return JSXResults;
}

  componentDidMount(){
    let defaultChart = this.props.chartDefault;
    let endUseCharts = this.props.endUseCharts;
    let firstChartTitle = Object.keys(endUseCharts)[0];
    let defaultDropdownLabel = endUseCharts[firstChartTitle]['title'];

    for (var property in endUseCharts) {
      if (endUseCharts.hasOwnProperty(property)) {
          if(property === defaultChart){
            defaultDropdownLabel = endUseCharts[property]['title'];
          }
      }
    }

    this.setState({currentSelectedChart: defaultDropdownLabel});
  }

  render(){
    return(
      <div>
      <p>Select a census division:</p>
        <div
        className='rectangle-dropdown-container'>
            <button
            className='rectangle-dropdown-button btn'
            onClick={()=>this.toggleChartOptions(true)}
            onBlur={()=>this.toggleChartOptions()}
            tabIndex="0">
              {this.state.currentSelectedChart}
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
            <div className={this.state.showChartDropdownOptions ? 'rectangle-dropdown-content' : 'display-none'}>
              {this.buildDropdownOptions(this.props.endUseCharts)}
            </div>
          <div className='dropdown-side-text'>
            <a href='https://www.ncdc.noaa.gov/monitoring-references/maps/us-census-divisions.php'
            target='_blank' rel='noopener noreferrer'>View US Census division map</a>
          </div>
        </div>
        </div>
    )
  }
}

Dropdown.propTypes = {

}

export default Dropdown;

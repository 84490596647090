import React from 'react';
import VerticalCard from './verticalCard.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const Carousel = (props) => {
  if(props.cards.length === 0) {
      return(<div></div>)
  } else {
      const buildCards = (cards) => {
        return cards.map( card => {
          return(
            <VerticalCard
              data-test='vertical-card'
              leadImage={card.lead_image}
              title={card.title || card.name}
              path={card.path}
              type={props.type}
              cardData={card}
              body={card.body}
              key={card.id}
              ReactPiwik={props.ReactPiwik}
            />
          )
        })
      }

      let settings;
      let cardsInOneStack;
      if (props.view === 'full-page') {
        cardsInOneStack = 6
        settings = {
          arrows: true,
          dots: true,
          infinite: props.cards.length > cardsInOneStack,
          speed: 500,
          slidesToShow: cardsInOneStack,
          slidesToScroll: cardsInOneStack,
          initialSlide: 0,
          responsive: [
          //Responsive breakpoints and settings
          {
            breakpoint: 1470,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: props.cards.length > 5,
              dots: true

            }
          },
          {
            breakpoint: 1324,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: props.cards.length > 4,
              dots: true
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite:  props.cards.length > 3,
              dots: false
            }
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: props.cards.length > 2,
              dots: false
            }
          },
          {
            breakpoint: 470,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: props.cards.length > 1,
              dots: false
            }
          }
        ]
      };
      //if not full page (in Detail.js component)
    } else if (props.view === 'half-page')  {
      cardsInOneStack = 3;
      settings = {
        arrows: true,
        dots: true,
        infinite: props.cards.length > cardsInOneStack,
        speed: 500,
        slidesToShow: cardsInOneStack,
        slidesToScroll: cardsInOneStack,
        initialSlide: 0,
        responsive: [
          //Responsive breakpoints and settings
          {
            breakpoint: 1350,
            settings: {
              arrows:true,
              slidesToShow: 3,
              slidesToScroll: 2,
              dots: false,
              infinite: props.cards.length > 2,
            }
          },
          {
            breakpoint: 1100,
            settings: {
              arrows:true,
              dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: props.cards.length > 1,
            }
          },
          {
            breakpoint: 800,
            settings: {
              arrows:true,
              dots: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: props.cards.length > 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              dots: false,
              slidesToShow: 3.3,
              slidesToScroll: 1,
              infinite: props.cards.length > 1,
            }
          },
          {
            breakpoint: 595,
            settings: {
              arrows: false,
              dots: false,
              slidesToShow: 1.05,
              slidesToScroll: 1,
              infinite: props.cards.length > 1,
            }
          }
        ]
      };
    } else {
        cardsInOneStack = 3;
        settings = {
          arrows: true,
          dots: true,
          infinite: props.cards.length > cardsInOneStack,
          speed: 500,
          slidesToShow: cardsInOneStack,
          slidesToScroll: cardsInOneStack,
          initialSlide: 0,
          responsive: [
            //Responsive breakpoints and settings
            {
              breakpoint: 1350,
              settings: {
                arrows:true,
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                infinite: props.cards.length > 2,
              }
            },
            {
              breakpoint: 1100,
              settings: {
                arrows:true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: props.cards.length > 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: props.cards.length > 1,
              }
            },
            {
              breakpoint: 595,
              settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: props.cards.length > 1,
              }
            }
          ]
        };
    }

      return(
        <div
        data-test='carousel'
        id={props.scrollToID}
        className={`${props.tableOfContentsClass} carousel clear no-print`}>
          <h3>{props.title}</h3>
          <Slider {...settings}>
            {buildCards(props.cards)}
          </Slider>
        </div>
      )
    }
  }


Carousel.propTypes = {

}

export default Carousel;

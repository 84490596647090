import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const MobileScreen = (props) => {

  if (props.screenOpen === false) {
    return null
  }

  else {
    if (props.screenLevel === 'home') {
      return(
        <nav className='main-nav open home'>
          <ul>
            <li>
              <Link to='/' onClick={()=>props.toggleScreen(false, 'home')}>Home</Link>
            </li>
            <li>
              <Link to='' onClick={()=>props.updateScreenLevel('biz')}>Business Types<FontAwesomeIcon icon={faChevronRight} /></Link>
            </li>
            <li>
              <Link to='' onClick={()=>props.updateScreenLevel('tech')}>Technologies<FontAwesomeIcon icon={faChevronRight} /></Link>
            </li>
            <li>
              <Link to='#contact-us' onClick={()=>props.scrollToContact(false, 'home')}>Contact Us</Link>
            </li>
            <li>
              <a href={props.buildCustomizations.url}>{props.buildCustomizations['back_to_site_text']}</a>
            </li>
          </ul>
         </nav>
      );
    } else if (props.screenLevel === 'biz') {
      return(
        <nav className='main-nav biz-type open'>
          <h2>Business Types</h2>
          {props.buildMobileNavCategories(props.categoriesBiz)}
          <Link to={props.URLPathsBiz} className='nav-view-all' onClick={()=>{
            props.toggleScreen(false, 'home')
          }}>View all business types</Link>
          <Link to='' className='back-btn' onClick={()=>props.updateScreenLevel('home')}><FontAwesomeIcon icon={faChevronLeft} /> Back</Link>
         </nav>
      )
    } else if (props.screenLevel === 'tech') {
      return(
         <nav className='main-nav tech open'>
          <h2>Technologies</h2>
          {props.buildMobileNavCategories(props.categoriesTech)}
          <Link to={props.URLPathsTech} className='nav-view-all' onClick={()=>{
            props.toggleScreen(false, 'home')
          }}>View all technologies</Link>
          <Link to='' className='back-btn' onClick={()=>props.updateScreenLevel('home')}><FontAwesomeIcon icon={faChevronLeft} /> Back</Link>
         </nav>
      )
    }
  }
}


MobileScreen.propTypes = {

};

export default MobileScreen;

import React from 'react';
import Header from '../elements/headers/header.js';
import NoMatchBackground from '../../images/404-image.jpg'

const NoMatch = (props) => {
  return(
    <div data-test='no-match'>
      <Header
        type={props.error}
        title={props.errorTitle}
        style={ NoMatchBackground }
        statusText={props.statusText}
        sub404={props.sub404}
      />
    </div>
  )
};

NoMatch.propTypes = {

}

export default NoMatch;

import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import NoMatch from './noMatch.js'
import Loader from 'react-loaders';
import Header from '../elements/headers/header.js';
import Breadcrumbs from '../elements/navs/breadcrumbs.js';
import Carousel from '../elements/cards/carousel.js';
import LinksBlock from '../elements/blocks/linksBlock.js';
import TextExtendButton from '../elements/buttons/textExtendButton.js';
import TableOfContents from '../elements/navs/tableOfContents.js';
import Dropdown from '../elements/navs/dropdown.js';

class Detail extends Component {

  constructor(props){
    super(props);
    this.state = {
      detailData:[],
      body:'',
      tocNodeList:[],
      currentContent:'toc-0',
      headerPositionArray:{},
      breadcrumbLocations:[],
      blockLocation: [],
      showSocialMediaOptions: false,
      topicFilteredCustomerContent: [],
      topicFilteredCaseStudies: [],
      relatedContent: [],
      showChartDropdownOptions: false,
      isError: false,
      displayTOC:true,
      displayBacktoTop: false
    }
    //event listeners that are added in componentDidMount() but will need to be removed on componentWillUnmount(). Must be binded under the constructor.
    this.updateTocOnScroll = this.updateTocOnScroll.bind(this);
    this.determineHeaderPositions = this.determineHeaderPositions.bind(this);
    this.showTOC = this.showTOC.bind(this);
  }

  //takes in HTML body from article fetch call, modifies the html to contain ids and classes for each header (important for table of contents), and passes new html as the body.
  modifyHTML(body){
    //create an HTML element and set our string 'html' into the element
    let html = document.createElement( 'html' );
    html.innerHTML = body;
    //find all of the headers and subheaders
    let tocNodeList = html.querySelectorAll('h2,h3');
    //loop through each header - add an id and class for table of contents and styling
    var counter = 0;
    for (let i = 0; i < tocNodeList.length; i++) {
      //if header is within a figure, or has a class of sidebarm or has no value then do not assign it a TOC id
      if(tocNodeList[i].parentNode.localName !== 'figure'
      && tocNodeList[i].innerText.length > 1
      && tocNodeList[i].parentNode.className !== 'sidebar'
      && tocNodeList[i].parentNode.parentNode.localName !== 'figure'){
        tocNodeList[i].setAttribute('id',`toc-${counter}`);
        //different classes are given for headers and sub headers. This will later be used when a users' screen height is shorter than 650px and we need to hide sub-headers
        tocNodeList[i].localName === 'h2' ? tocNodeList[i].setAttribute('class','detail-header main-toc') : tocNodeList[i].setAttribute('class','detail-header sub-toc');

        counter++;
      }
    }
    // find endUseCharts id if it exists and add the chart dropdown
    let figureTag = html.querySelectorAll('#endUseCharts')[0];
    if(figureTag !== undefined){
      let chartDropdownDiv = document.createElement('div');
      chartDropdownDiv.id = 'chart-dropdown';

      // polyfill for .prepend() since method is not available in IE
      (function (arr) {
        arr.forEach(function (item) {
          if (item.hasOwnProperty('prepend')) {
            return;
          }
          Object.defineProperty(item, 'prepend', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function prepend() {
              var argArr = Array.prototype.slice.call(arguments),
                docFrag = document.createDocumentFragment();
              argArr.forEach(function (argItem) {
                var isNode = argItem instanceof Node;
                docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
              });

            this.insertBefore(docFrag, this.firstChild);
            }
          });
        });
      })([Element.prototype, Document.prototype, DocumentFragment.prototype]);

      figureTag.prepend(chartDropdownDiv);
    }

    //set this modified HTML as state, and this.state.body will be used for the finaly rendered HTML
    return html.innerHTML;
  }

  //Stores all of the header titles in a nested array with their corresponding page position as the second item in the array.
  //[
    //['toc-1',678],
    //['toc-2', 2345]
  //]
  //Storing it in this array allows us to loop through it. Array.keys and Array.values is not supported by IE (yet).
  determineHeaderPositions(){
    if(window.innerWidth > 768){
      //if users browser height is lower than 650px then only gather toc headers that are H2 elements.
      let headers = window.innerHeight > 650 ? document.querySelectorAll('.detail-header') : document.querySelectorAll('.main-toc');
      //store Nodelist of headers as state, which will be passed as props to <TableOfContents/>. Important that we capture the node list in this function because it will include related content / case studies / other content that is not necessarily in the main body article.
      let headerPositionArray = [];
      for (let i = 0; i < headers.length; i++) {
          let headerOffsetTop = Math.round(headers[i].getBoundingClientRect().top + document.documentElement.scrollTop)-1;
          let headerPosition = [headers[i].id, headerOffsetTop];
          headerPositionArray.push(headerPosition)
      }
      this.setState({tocNodeList:headers,
      headerPositionArray:headerPositionArray})
    }
  }

  //updates the table of contents 'bookmark' bar to indicate where they are in the table of contents when user scrolls through content.
  updateTocOnScroll(){
    if(window.innerWidth > 768 && this.state.headerPositionArray[0][1] !== undefined){
      let scrollPosition = document.documentElement.scrollTop
      let tocArray = this.state.headerPositionArray;
      if(scrollPosition >= tocArray[0][1] && scrollPosition <= tocArray[1][1]){
        //only change state if it's not the current state anymore
        this.state.currentContent !== tocArray[0][0] && (this.setState({currentContent:tocArray[0][0]}))
      } else if(scrollPosition >= tocArray[tocArray.length - 1][1]){
        //only change state if it's not the current state anymore
        this.state.currentContent !== tocArray[tocArray.length - 1][0] && (this.setState({currentContent:tocArray[tocArray.length - 1][0]}))
      } else if (scrollPosition > tocArray[0][1] ) {
        for (var i = 1; i < tocArray.length -1; i++) {
            if(scrollPosition > tocArray[i][1] && scrollPosition < tocArray[i+1][1]){
              //only change state if it's not the current state anymore
              this.state.currentContent !== tocArray[i][0] && (this.setState({currentContent:tocArray[i][0]}))
            }
          }
      }
    }
  }

  //moves social media sharing pop-up window to the center of the screen after user clicks buttons to share on twitter/linkedin/etc.
  popupCenter(url, boxWidth, boxHeight) {
    let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    let dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;
    let systemZoom = width / window.screen.availWidth;
    let left = (width - boxWidth) / 2 / systemZoom + dualScreenLeft
    let top = (height - boxHeight) / 2 / systemZoom + dualScreenTop
    let newWindow = window.open(url, '', 'scrollbars=yes, width=' + boxWidth / systemZoom + ', height=' + boxHeight / systemZoom + ', top=' + top + ', left=' + left);
    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();
  }

  toggleSocialMediaOptions(isButtonClick){
    isButtonClick === true ? this.setState( previousState => ({showSocialMediaOptions:!previousState.showSocialMediaOptions})) : this.setState({showSocialMediaOptions:false});
  }

  //returns an array of customer content that is filtered to the current report the user is viewing.
  buildCustomerContent(links, relatedTopicIds){
    let result = []
    //links is the customer related content (array of objects)
    //relatedTopicIds is an array of related topic IDs for the article you're viewing
    for(let i = 0; i < links.length; i++){
      for(let j = 0; j < links[i].topics.length; j++){
        if(relatedTopicIds.indexOf(links[i].topics[j].id) > -1 && result.indexOf(links[i]) === -1) {
          result.push(links[i]);
        }
      }
    }
    return result;
  }

  //returns an array of case studies that are only for business types (case studies will not show up for technology articles)
  buildCaseStudies(detailData, links){
    let result = [];
    if(detailData['categories'][0]['parent'].toLowerCase() === 'business types'){
      let detailTermID = detailData['topics'][0]['id'];
      for(let i = 0; i < links.length; i++){
        for(let j = 0; j < links[i].topics.length; j++){
          if(links[i].topics[j].id === detailTermID){
            result.push(links[i]);
          }
        }
    }
  }
  return result;
  }

  //filters out excluded Categories from the list of topic ids. The result will be used in the related content fetch call.
  buildTopicsArray(topics, excludedCategories){
    let result;
    let topicIdArr = topics.map( topic => {
      return topic.id
    });

    if(excludedCategories !== null){
      let excludedCategoriesArr = excludedCategories.split(', ');
      let filteredTopicIds = topicIdArr.filter(
        function(node){
          return this.indexOf(node) < 0;
        },excludedCategoriesArr
      );
      result = filteredTopicIds;
    } else {
      result = topicIdArr;
    }

    return result;
  }

  showTOC(){
    if(document.getElementsByClassName('text-extend-button')[0] !== undefined){
      let triggerScrollPosition = document.getElementsByClassName('text-extend-button')[0].offsetTop;
      let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollPosition > triggerScrollPosition && this.state.displayTOC === false){
        this.setState({displayTOC:true})
      } else if(scrollPosition < triggerScrollPosition && this.state.displayTOC === true ) {
        this.setState({displayTOC:false})
      }

    }
  }
  //swaps out chart depending on user selection from Dropdown component showing census divisions. Note: Dropdown.js is being rendered only through ReactDOM.render() under componentDidMount().
  updateCharts(charts){
    //electric charts
    document.getElementsByClassName('electric-chart')[0].setAttribute('src',charts['electric-chart']['src']);
    document.getElementsByClassName('electric-chart')[0].setAttribute('alt',charts['electric-chart']['alt_text']);
    document.getElementsByClassName('electric-chart')[0].setAttribute('title',charts['electric-chart']['title_text']);
    //gas charts
    document.getElementsByClassName('gas-chart')[0].setAttribute('src',charts['gas-chart']['src']);
    document.getElementsByClassName('gas-chart')[0].setAttribute('alt',charts['gas-chart']['alt_text']);
    document.getElementsByClassName('gas-chart')[0].setAttribute('title',charts['gas-chart']['title_text']);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.props.ReactPiwik.push(['setCustomUrl', window.location.href])
    this.props.ReactPiwik.push(['trackPageView']);

    const { id } = this.props.match.params

    var response;
    try {
      var response;
      var draftText = '';

      // If this is a draft then change the endpoint
      if(this.props.draft) {
        let adminURL = `${this.props.URLPaths.adminURL}/user/login?destination=${this.props.URLPaths.article}/${id}`
        //@TODO Adjust this HTML to make it look professional
        this.setState({draftText:<div className='draft-status'><h2>This is a draft</h2><a href={adminURL} target='blank'>Admin URL</a></div>})

        response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.draft}/${id}?default_division=${this.props.chartDefault}&chart_order=${this.props.chartOrder}&access_token=${this.props.accessToken}`);
      }
      else {
        //fetch data for article page
        response = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.article}/${id}?default_division=${this.props.chartDefault}&chart_order=${this.props.chartOrder}&access_token=${this.props.accessToken}`);
      }

      if(response.status !== 200) {
        console.log('Feth Error', response)
        this.setState({error:response.status, statusText: response.statusText, isError: true})
      }
    }
    catch(err) {
      console.log('Feth Error', err)
      this.setState({ isError:true, error:400, statusText: 'Could not fetch article' })
    }

    if (!this.state.isError) {
      // customer view of a detail component with no errors:
      try {
        const detailDataJson = await response.json();
        //creating a topics array for the relatedContent fetch call later. This will filter out excluded categories if there are any.
        let topicsArray = this.buildTopicsArray(detailDataJson.topics, this.props.excludedCategories);
        //fetch data for related content and exclude any articles if they exist.
        const relatedContentResponse = await fetch(`${this.props.URLPaths.url}${this.props.URLPaths.relatedContent}/${topicsArray}?exclude_articles=${this.props.excludedArticles},${detailDataJson.id}&access_token=${this.props.accessToken}`);
        const relatedContentJson = await relatedContentResponse.json();

        //Building breadcrumb link(s) that will be stored in state and passed down as props to Breadcrumb component
        let hierarchyData = detailDataJson;
        let breadcrumbLinks = this.props.buildBreadcrumbLinks('detailPage', hierarchyData);
        //Block setting logic
        let blockType = detailDataJson['categories'][0]['parent'];
        // take all customer content (which was passed as props from App.js) and filter them based on topic relevance for this detail page

        let relatedTopicIds = detailDataJson.topics.map( topic => {
          return topic.id;
        })
        let customerContentResults = this.buildCustomerContent(this.props.customerContent.customer_resource,relatedTopicIds);

        let caseStudyResults = this.buildCaseStudies(detailDataJson,this.props.customerContent.case_studies);
        //inject IDs and classes into headers for dynamic table of contents
        let modifyHTMLResult = this.modifyHTML(detailDataJson.body);
        document.title = `Business Energy Advisor | ${detailDataJson['title']}`;
        this.setState({detailData:detailDataJson,
          breadcrumbLocations:breadcrumbLinks,
          blockLocation:blockType,
          topicFilteredCustomerContent: customerContentResults,
          topicFilteredCaseStudies: caseStudyResults,
          body: modifyHTMLResult,
          relatedContent:relatedContentJson
        })
        //Inserts a Dropdown component under our charts if one exists. Note that this component DOES NOT re-render if state updates in it's parent component (here, in Detail.js) because it is only rendered once in componentDidMount. This is why Dropdown.js is a stateful component and will be responsible for managing its own state. However, callbacks are still possible which is how we are updating the chart based on user selection using updateCharts().
        if(this.state.detailData.end_use_charts !== undefined){
          ReactDOM.render(
            <Dropdown
            endUseCharts={this.state.detailData.end_use_charts}
            showChartDropdownOptions={this.state.showChartDropdownOptions}
            updateCharts={this.updateCharts.bind(this)}
            chartDefault={this.props.chartDefault}
            />,
            document.querySelector('#chart-dropdown'));
          }
          //store Nodelist of headers as state, which will be passed as props to <TableOfContents/>. Important that we capture the node list in this function because it will include related content / case studies / other content that is not necessarily in the main body article.
          // NOTE: will need to troubleshoot why values are occasionally incorrect
          this.determineHeaderPositions();
          //dynamic height / line height for window screens that are shorter
          let tocHeight = document.getElementsByClassName('side-nav')[0].clientHeight;
          if(tocHeight > 600){
            document.getElementsByClassName('side-nav')[0].className += ' reactive-toc'
          }
          //enable scroll event listener and invoke function that will update table of contents 'bookmark' bar
          window.addEventListener('scroll', this.updateTocOnScroll.bind(this));
          //if user resizes the window after initial render, determine new header positions.
          window.addEventListener('resize', this.determineHeaderPositions.bind(this));
          //detects if user adds a hash in their browser url and sends them to the correct location
          if(window.location.hash !== ""){
            try{
              document.getElementById(window.location.hash.substring(1)).scrollIntoView();
            }
            catch(err){
              console.log('Hash in the URL is invalid for this page. Scrolling into view canceled.');
            }
          }
          // Internet Explorer 6-11
          var isIE = /*@cc_on!@*/false || !!document.documentMode;
          if(isIE){
            this.setState({displayTOC:false})
            window.addEventListener('scroll', this.showTOC.bind(this));
        }
      } catch(err) {
        console.log('Feth Error', err)
        this.setState({ isError:true, error:400, statusText: 'Could not fetch article' })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateTocOnScroll);
    window.removeEventListener('resize', this.determineHeaderPositions);
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE){
      window.removeEventListener('scroll', this.showTOC, false);
    }
  }

  render() {
    if(this.state.isError === true){
      return(
        <div data-test='detail-fetch detail' className='detail'>
          <NoMatch error={this.state.error} statusText={this.state.statusText}/>
        </div>
      )
    } else if(this.state.detailData.length === 0 || this.state.breadcrumbLocations.length === 0 || Object.keys(this.props.URLPaths).length === 0 ){
      return (
        <div data-test='detail-fetch detail' className='detail'>
          <Loader type='line-scale'
          style={{transform: 'scale(2)'}}/>
        </div>
      )
    }
      return(
        <div data-test='detail-fetch detail' className='detail'>
            <div className='draft-text-container'>
              {this.state.draftText}
            </div>
            <Header
            data-test='header'
            type={'detail'}
            title={this.state.detailData.title}
            style={this.state.detailData['lead_image']['src']}
            medium={this.state.detailData['lead_image']['medium']}
            />
           <Breadcrumbs
            data-test='breadcrumbs'
            locations={this.state.breadcrumbLocations}
            />
           <TextExtendButton
            data-test='text-extend-button'
            showSocialMediaOptions={this.state.showSocialMediaOptions}
            customerName={this.props.customerName}
            pageTitle={this.state.detailData['title']}
            ReactPiwik={this.props.ReactPiwik}
            baseURL={this.props.URLPaths.url}
            toggleSocialMediaOptions={this.toggleSocialMediaOptions.bind(this)}
            popupCenter={this.popupCenter.bind(this)}
            nodeID={this.state.detailData['id']}
            accessToken={this.props.accessToken}
            />
           <div className='container-centered'>
              <TableOfContents
              data-test='side-nav'
              className='side-menu'
              tocNodeList={this.state.tocNodeList}
              currentContent={this.state.currentContent}
              customerName={this.props.customerName}
              ReactPiwik={this.props.ReactPiwik}
              displayTOC={this.state.displayTOC}
              />
              <div className='main-body'>
                <div
                dangerouslySetInnerHTML={{__html: this.state.body}}/>
                  <div className='date'>{this.state.detailData.date}</div>
                  <Carousel
                  data-test='carousel'
                  scrollToID='programs_and_resources'
                  title='Programs and resources'
                  tableOfContentsClass='detail-header main-toc'
                  type='customer_resource'
                  cards={this.state.topicFilteredCustomerContent}
                  view='customer_resource'
                  />
                  <LinksBlock
                  data-test='links-block'
                  tableOfContentsClass='detail-header main-toc'
                  scrollToID='case-studies'
                  caseStudies={this.state.topicFilteredCaseStudies}
                  baseUrl={this.props.URLPaths.url}
                  accessToken={this.props.accessToken}
                  />
                  <Carousel
                  data-test='carousel'
                  scrollToID='related-content'
                  title='Related content'
                  cards={this.state.relatedContent}
                  tableOfContentsClass='detail-header main-toc'
                  view='half-page'
                  />
                </div>
              </div>
              <div className='row bg-gray text-center cta-block no-print'>
                {this.props.renderCTAButton(this.state.detailData.categories[0]['parent'], 'Detail')}
              </div>
        </div>
        )
    }
}

Detail.propTypes = {

};

export default Detail;

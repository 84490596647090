import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'matchmedia-polyfill';
import 'matchmedia-polyfill/matchMedia.addListener';
import objectFitImages from 'object-fit-images';
import 'whatwg-fetch';

require('es6-promise').polyfill();
require('core-js');
objectFitImages();
ReactDOM.render(<App />, document.getElementById('bea'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

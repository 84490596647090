import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../navs/searchBar.js';

const Header = (props) => {

  var leadImage;
  //if the subdomain is invalid, the nav and footer will not show in the error page. To prevent blank areas in the screen, we need to have additional styles for the header image to cover the entire page.
  if(props.type === 404 && props.sub404){
    leadImage = {
      background: `linear-gradient(rgba(0,0,0,.45),rgba(0,0,0,.45)), url(${props.style}) center center`,
      backgroundSize: 'cover',
      position: 'absolute',
      left: '0',
      top: '0',
      height: '100%'
    }
  } else {
    leadImage = {
      background: `linear-gradient(rgba(0,0,0,.45),rgba(0,0,0,.45)), url(${props.style}) center center`,
      backgroundSize: 'cover'}
  }

    //determine page specific header style
    let renderHeaderContent = (type) => {
      if(type === 'home'){
        //header of the home page
        return(
          <div>
            <SearchBar type={'header'}/>
            <div className='header-container'>
              <div className='width-70'>
                  <div className='home-header' dangerouslySetInnerHTML={ { __html: props.CtaBlockObj['home-page-intro'] } }></div>
                <div className='clear row-center'>
                  <div className='width-80'>
                    <div className='col-2-container'>
                      <Link to={props.URLPaths.businessTypes} className='card col-2'>
                        <div
                        className='cta-block' dangerouslySetInnerHTML={{ __html: props.CtaBlockObj['browse-by-business-types-card']}}/>
                      </Link>
                      <Link to={props.URLPaths.technologies} className='card col-2'>
                        <div
                        className='cta-block' dangerouslySetInnerHTML={{ __html: props.CtaBlockObj['browse-by-technologies-card']}}/>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      //if the subdomain is invalid - do not show the search field.
      } else if (type === 404 && props.sub404){
        return(
          <div className='header-container page-not-found'>
            <h1>There’s Nothing Here</h1>
            <p>That’s not a URL we recognize&mdash;please check your URL and try again. If you continue to have errors, call 1-800-ESOURCE (1-800-376-8723) for assistance.</p>
          </div>
        )
      //all other client errors
      } else if (type === 404) {
        return (
          <div>
            <SearchBar type={'header'}/>
            <div className='header-container page-not-found'>
              <h1>{props.statusText}</h1>
              <h2>We can&rsquo;t seem to find the page you&rsquo;re looking for&mdash;it may have been moved or removed.</h2>
              <p>Don’t despair! We have more information to help you save energy and money for your business. Try these links:</p>
              <div className='btn-container'>
                <a className='btn' href="/"> Home </a>
                <a className='btn' href="/categories/business-types"> View all business types </a>
                <a className='btn' href="/categories/technologies"> View all technologies </a>
              </div>
            </div>
          </div>
        )
      //most likely server error
      } else if (type >= 400){
        return(
            <div className='header-container page-not-found'>
              <h1>{props.statusText}</h1>
              <h2>There was an error connecting to the Business Energy Advisor. Please try again later.</h2>
              <h2>Error Code: {props.type}</h2>
            </div>
        )
      //header for category and detail pages
      } else {
        return(
          <div>
            <SearchBar type={'header'}/>
            <div className='header-container'>
              <h1>{props.title}</h1>
            </div>
          </div>
        )
      }
    }

    return (
        <header className='header' data-test='header' style={leadImage}>
          {renderHeaderContent(props.type)}
        </header>
    );
  }


Header.propTypes = {

};

export default Header;

import React from 'react';
import Loader from 'react-loaders';


const SearchResults = (props) => {
  let y = props.page;
  return(
     <div>
       <div className='list'>
         {props.list.map(item => <div className='list-row' key={item.id}>
           <h3><a href={item.path}>{item.title}</a></h3><p>{item.teaser}</p>
         </div>)}
       </div>
        <div className='interactions'>
          {
            props.isLoading &&
            <span>
              <Loader type='line-scale'
              style={{transform: 'scale(2)'}}/>
            </span>
        }
        </div>
        <div className='interactions'>
          {
            (props.searchResultsLength !== props.totalResults) &&
            <button
              type='button'
              className='btn' onClick={() => {props.fetchNewStories(props.searchValue, (y * 1 + 10))}}
            >
            1&ndash;{props.list.length} of {props.totalResults}
          </button>
          }
      </div>
    </div>
  )
}

SearchResults.propTypes = {

};

export default SearchResults;

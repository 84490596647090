import React from 'react';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';



const Footer = (props) => {

  //Google Analytics tracking
  const google_trackingId = props.buildCustomizations.google_analytics_code;
  if(google_trackingId != ""){
    ReactGA.initialize(google_trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

    return(
      <footer id='contact-us' className='footer-container container no-print'>
        <div className='row-center'>
          <div className='clear col-3-container'>
            <div className='contact float-left col-3'>
              <h3>Contact</h3>
              <div
              className='contact-info' dangerouslySetInnerHTML={{ __html: props.buildCustomizations['contact_us_info'] }}/>
            </div>
            <div className='navigation float-left col-3'>
                <h3>
                  <Link to={props.URLPaths.businessTypes}>Business Types</Link>
                </h3>
              <ul>
                {props.buildFooterNavigation(props.businessCategories)}
              </ul>
            </div>
            <div className='navigation float-left three-col'>
              <h3>
                <Link to={props.URLPaths.technologies}>Technologies</Link>
              </h3>
              <ul>
              {props.buildFooterNavigation(props.technologyCategories)}
              </ul>
            </div>
          </div>
        </div>
        <div className='copy-right'>All content copyright © 1986-{(new Date().getFullYear())} E Source Companies LLC. All rights reserved.</div>
      </footer>
    )
};

Footer.propTypes = {

};

export default Footer;

import React, {Component} from 'react';
import Loader from 'react-loaders';
import Header from '../elements/headers/header.js';
import Carousel from '../elements/cards/carousel.js';

class Home extends Component{

  renderCardRow(customerContent){
    if (customerContent.length !== undefined || customerContent.length > 0) {
        return(
          <div className='row home-carousel'>
          <Carousel
            data-test='carousel'
            title='Resources'
            cards={customerContent}
            view='customer_resource'
            type='customer_resource'
            ReactPiwik={this.props.ReactPiwik}
            />
        </div>
      )
    }
  }

  componentDidMount(){
    this.props.ReactPiwik.push(['setCustomUrl', window.location.href])
    this.props.ReactPiwik.push(['trackPageView']);
    window.scrollTo(0, 0);
    document.title = `${this.props.buildCustomizations['name']} | BEA | Home`;
  }

  render(){
    if(this.props.URLPaths.length === 0) {
        return(
          <div data-test='home' className='home container'>
            <Loader type='line-scale'
            style={{transform: 'scale(2)'}}/>
          </div>
        )
      } else {
      return(
        <div data-test='home' className='home container'>
            <Header
              data-test='headerHome'
              URLPaths={this.props.URLPaths}
              CtaBlockObj={this.props.CtaBlockObj}
              style={this.props.buildCustomizations['lead_image']['src']}
              type={'home'}
            />
              {this.renderCardRow(this.props.buildCustomizations.content.customer_resource)}
            <div className='row-center text-left contact-us'>
              <div className='col-1090-container'>
                <div className="col-10-pct">
                  <img alt="phone icon" className="img-width-80" data-entity-type="" data-entity-uuid="" src="/images/phone-icon.png" />
                </div>
                <div className="col-90-pct">
                  <h3>Have more questions? We&#39;re here to help.</h3>
                  <div
                  className='contact-info' dangerouslySetInnerHTML={{ __html: this.props.buildCustomizations['contact_us_info'] }}/>
               </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

Home.propTypes = {

};

export default Home;

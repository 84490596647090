import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


class SearchBar extends Component {

  constructor(props){
    super(props);
    this.state = {
      searchValueInput:'',
    }
  }
  submitSearch(event,trigger){
    if ((this.state.searchValueInput === '' && event.key === 'Enter') || (this.state.searchValueInput === '' && trigger === 'mobile-submit')){
      return;
    }
    if (event.key === 'Enter' || trigger === 'mobile-submit') {
      this.props.history.push(`/search/${this.state.searchValueInput}`);
    }
  }
  updateSearchValue(event){
    this.setState({searchValueInput:event.target.value})
  }
  async componentDidMount(){
    const { id } = this.props.match.params;
    let searchValue = id;

    this.setState((props) => ({
      searchValueInput:searchValue
    }));
  }

  render(){
    if (this.props.type === 'search'){
      return (
       <div className='clear search-container no-print'>
           <input
             id='search'
             placeholder='Search'
             className='form-control form-text float-right'
             type='text'
             value={this.state.searchValueInput}
             onKeyUp={(event) => {this.submitSearch(event)}}
             onChange={(event) => {this.updateSearchValue(event)}}
           />
           <button className='btn'
           id='search-button'
           onClick={(event) => {this.submitSearch(event, 'mobile-submit')}}>
             <FontAwesomeIcon icon={faSearch} />
           </button>
       </div>
      )
    } else {
      return (
        <div className='clear search-container no-print'>
            <input
              id='search'
              placeholder='Search'
              className='form-control form-text float-right'
              type='text'
              onKeyUp={(event) => {this.submitSearch(event)}}
              onChange={(event) => {this.updateSearchValue(event)}}
            />
            <button className='btn'
            id='search-button'
            onClick={(event) => {this.submitSearch(event, 'mobile-submit')}}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
      )
    }
  }
}



SearchBar.propTypes = {

};

export default withRouter(SearchBar);
